import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Container from "../components/container"
import Metadata from "../components/metadata"
import Tag from "../components/article/tag"
import IconHeading from "../components/heading/icon-heading"

import TagsIcon from "../components/images/heading-tags.svg"

const TagsPage = (
  {
    data: {
      allMarkdownRemark: { group },
    },
  },
) => (
  <Container>
    <Metadata title="All tags"/>

    <div>
      <h1>Browse tags</h1>
      <p>Choose a tag from the list to view all articles marked with that tag.</p>

      <IconHeading text="All tags" image={TagsIcon} style={{ marginTop: 70 + "px", marginBottom: 25 + "px" }}/>

      <div>
        {group.map(tag => (
          <Tag key={tag.fieldValue} tag={tag.fieldValue}/>
        ))}
      </div>
    </div>
  </Container>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired,
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`
